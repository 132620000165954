import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export class Marquee {
    constructor() {
        gsap.registerPlugin(ScrollTrigger);
        let black = '#0A0A0A';
        let offWhite = '#EDEEE9';

        // saveStyles is used because GSAP writes inline CSS for styling.
        // If we resize our browser, we want the styling of each viewport-based animation to be saved.
        ScrollTrigger.saveStyles(".first, .second, .container");
        
        ScrollTrigger.matchMedia({
          
          // the mobile animations are the same as the desktop, except the font colors are different
          // the scrub is delayed by 1s with relation to the scrollbar (scrub: 1)
          // the animation starts a bit earlier
          "(max-width: 768px)": function() {
            
            let mobileTL = gsap.timeline({
              scrollTrigger: {
                trigger: ".marquee2",
                start: "-100% bottom",
                scrub: 1,
              }
            });
        
            mobileTL.to(".first", {duration: 2, xPercent: -100, color: offWhite})
                    .to(".second", {duration: 2, xPercent: 100, color: offWhite},"<")
                    .to(".container", {duration: 0.5, backgroundColor: black},"<")
          },
          
          // The tablet and desktop animations have a delay of 7s on scrubs (scrub: 2).
          "(min-width: 769px)": function() {
        
            let desktopTL = gsap.timeline({
              scrollTrigger: {
                trigger: ".marquee2",
                start: "-40% bottom",
                scrub: 5,
              }
            });
        
            desktopTL.to(".marquee2 .first", {duration: 2, xPercent: -20})
                    .to(".marquee2 .second", {duration: 2, xPercent: 15},"<")
                    .to(".container", {duration: 2},"<")
          }
        });


        let desktopTL2 = gsap.timeline({
          scrollTrigger: {
            trigger: ".marquee3",
            start: "-30% bottom",
            scrub: 5,
          }
        });

        var rotate = gsap.timeline({
          scrollTrigger: {
            trigger: ".products-cont",
            start: " 50% top",
            scrub: 4,
            markers: false
          }
        });

        rotate.to(".rotate", {duration: 2, rotation: 360*0.3, ease: "none"})

        desktopTL2.to(".marquee3 .first", {duration: 2, xPercent: -20})
        .to(".marquee3 .second", {duration: 2, xPercent: 15},"<")
        .to(".container", {duration: 2},"<")

        gsap.set(".footer", {top: "-400px", position:"relative"})
        gsap.to(".footer",{top: "0px", scrollTrigger:{
            trigger: ".footer-triger",
            scrub: true,
            start: "140% bottom",
            markers: false,
            end: "bottom 40%",
        }})
    }
}



new Marquee();